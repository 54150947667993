import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fdesign-lib%2Fstyle-theme%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA43STW%2FTQBAG4Du%2F4pX2AhKtPPu9w8mO7b%2BBnH6kLW1tQgoGxH9Ha8RhZ3NIrFweeTQz75iP83zC73fA1dVncnGd5ueGoQY%2F7IbuU%2BHEUKnrqGtK1wzlKIRgSzcM1XjTm1S6ze4M6aF0x1Dj9ivdZ3f5KT3kOWN%2BSo8M1dve9qb0xFCd6UynS58YKphggvB93ks77aj0G4ainnoS%2BdzmefQY5fx3eR4%2FOun3DGWMtU7kdsj7%2BtGPou%2FDv3zcIPJ83Lz3rcjnafMuerHXl81TIjHP83b32NKu9JfszlvnS39lKGvbthc5zDmfMeqd%2BE4Whmptn1Jb%2BleG8mHXuFD6kWGaZS3xG8NqiSeGjRLfGOQlfmdQ9eaPc2%2BuDLISfzJ01f0XgyqkhtHALyu0XVY0OB7203tt9Mf%2F%2F%2BY6fBA1lGv0siJeXKJzSe6QN7iwxuQaWlZcPplliGuSOxc6eYavYqPAiNUpKTJSlTslxvR2moVOvAUpdH%2FucnTD0HW3W0aNd4x6h%2FuzBz0wTK0PjHqsR4atez0xtvo%2FfwEYop3XdQUAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fcomponents%2Flayout%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5VU226jMBR85yvOy0qJFCPcAKHOy%2F5JZfBZsAI2a1xCW%2BXfKwPagEm06psvM%2BM5PmOHb5SawvR9BF8BQMNNKRWxumVAo7QdzgGA7tH8qfWVQSWFQHUObkH4jxcqbUlnZXH52EhEHrSTTVsjqZALNBt0ErvzFgS6gDCI2gH4u9VucB7XB3KVwlbOavTLLbW6k1ZqxcBgza3s0a1%2BEqkEDgzoSv1lVM95cSmNfleCFLrWhoEpc75L6AHS7ABZfIAopHunU6EsK8uAejaPo9DMlqpCI%2B15YdyZvs9Jrq3VDYOemx0hbzTJBq5rmu69ok7RXOjjqiwOlvBalopBgcqiWZmKl9f75Ei%2BX1GSkSJk19b8g0Fe6%2BKyrNtj56PhVQcWYql%2Fu%2FcqkqlZiz2DLXLLQOl56O138hMZFC6IP27zaTKiB6ciVel0lEVlSa6HR21quRAjLgKaeq3OZjEj0EyhVVrhCvK6gVjdusw8C9aCy%2Bem3YNNT5FnId9goszHFD7mNfUhYiMTxz4GR8y6%2FdcsU8fT8e9o%2FHeDQnLoCoOogCsBu0aqu7GXuB32o0i4%2FmfWDz%2FLpqDfVsAHv8Uz2v%2BcnNJsYySZFZ%2Bke3qOt%2BD2DZRhQ38iBQAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var bottomSpace = '_11rcrvve';
export var layoutContent = '_11rcrvv0';
export var lineBlock = '_11rcrvv1';
export var lineBlockHr = '_11rcrvv2';
export var pageSectionHeader = '_11rcrvv3';
export var pageSectionHeaderH2 = '_11rcrvv4';
export var sectionBlock = '_11rcrvv6';
export var sectionBlockContent = _7a468({defaultClassName:'_11rcrvv7',variantClassNames:{separator:{false:'_11rcrvv8',true:'_11rcrvv9'},wrapper:{lg:'_11rcrvva',md:'_11rcrvvb',sm:'_11rcrvvc',xl:'_11rcrvvd'}},defaultVariants:{},compoundVariants:[]});
export var spacer = '_11rcrvv5';